<template>
  <div class="bottom-menu">
    <div
      v-for="item in menu"
      :key="item.name"
      class="menu-item"
      :class="{ active: checkActive(item, item.activeUrl) }"
      @click="clickMenuItem(item)"
    >
      <BaseImg class="icon" :src="item.icon" :alt="item.name" />
      <p class="title">{{ item.name }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PAGE } from '~~/constants/router'
import { getBottomMenu } from '~/constants/menu'
import { IMenuBottom } from '~/types/menu.type'
import { LOBBY_GAME_URLS } from '~~/config/page-url'
import BaseImg from '~/components/common/base-img.vue'

const router = useRoute()
const route = useRoute()
const { toggleMenuAccount } = useToggleMenu()
const { BOTTOM_MENU } = getBottomMenu()
const menu = computed(() => {
  const removeItem = (item: string) => {
    return item !== (router.path === PAGE.HOME ? 'Trang Chủ' : 'Khuyến mãi')
  }
  return BOTTOM_MENU.filter((item) => removeItem(item.name))
})

const checkActive = (item: IMenuBottom, activeUrl: string) => {
  if (item.url === BOTTOM_MENU[1].url) {
    return false
  } else if (route.query.type === 'lottery' && route.name !== 'casino') {
    return route.fullPath === item.url || route.fullPath.includes(item.url)
  } else if (route.path === LOBBY_GAME_URLS.QUAY_SO && activeUrl === '/game') {
    return false
  } else {
    return route.fullPath.includes(item.url) || route.fullPath.includes(activeUrl)
  }
}

const clickMenuItem = (item: IMenuBottom) => {
  if (!item?.url) {
    toggleMenuAccount()
    return
  }
  navigateTo(item.url)
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 200)
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/common/menu-bottom/index.scss" />
